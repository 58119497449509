import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { HighlightedRate } from '@/share/HighlightedRate/HighlightedRate';
import {
  getFormattedRolloverPoints,
  getFormattedRolloverPointsDisplayed,
  getFormattedRolloverRate,
  getFormattedSpotRate,
  selectRfs,
} from '@/store/state/rfs/rfs.selectors';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';
import { Blinker } from '@/App/Home/Notifications';

interface RolloverRateProps {
  status: 'Idle' | 'Received' | 'Waiting';

  // Failed to do a proper union type
  spotRate?: string;
  rolloverPoints?: string;
  rolloverRate?: string;
}

export function RolloverRate() {
  const locale = useSelector(selectCurrentLocale);
  const rfs = useSelector(selectRfs);

  const rolloverRate = getFormattedRolloverRate(locale)(rfs);

  const status =
    rolloverRate === undefined ?
      'Idle' //
    : (
      rfs.status === 'requesting' //
    ) ?
      'Waiting' //
    : 'Received';

  const spotRate = getFormattedSpotRate(locale)(rfs);
  const rolloverPoints = getFormattedRolloverPoints(locale)(rfs);
  const rolloverPointsDisplay = getFormattedRolloverPointsDisplayed(locale)(rfs);

  return (
    <>
      <div className="pt-5 form-group mb-0">
        <label>
          <FormattedMessage id="rollover.rolloverRate" />
        </label>
        <div data-e2e="rollover-rate">{content({ status, spotRate, rolloverPoints, rolloverRate })}</div>
      </div>
      <div className="pt-5 form-group mb-0">
        <FormattedMessage
          id={`rollover.rateExplaination${status !== 'Received' ? '.empty' : ''}`}
          values={{
            spotRate: spotRate && <HighlightedRate value={spotRate} />,
            rolloverPoints: (
              <span className="text-info" data-e2e="rollover-points">
                {rolloverPointsDisplay}
              </span>
            ),
          }}
        />
      </div>
    </>
  );
}

const content = ({ status, rolloverRate }: RolloverRateProps) => {
  switch (status) {
    case 'Idle':
      return <div className="display-4 tabular-figures">-</div>;

    case 'Received':
      return (
        <div className="display-4 tabular-figures">
          <HighlightedRate value={rolloverRate!} size={3} offset={1} />
        </div>
      );

    case 'Waiting':
      return <Blinker />;

    default: {
      const exhaustiveCheck: never = status;
      return exhaustiveCheck;
    }
  }
};
