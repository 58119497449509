import type { Reducer } from '@reduxjs/toolkit';

import { isRolloverRoute } from '@/App/routes';
import { logger } from '@/logging/logger';
import { getBidAskValue } from '@/models/bidask-price';
import type { Rfs } from '@/models/rfs';
import { oppositeMasterAmount } from '@/models/way';
import type { AppAction } from '@/store/app.actions';
import { getDefaultCurrencies } from '@/utils/currencies';
import { getToday } from '@/utils/today';

const today = getToday();

export const DEFAULT_RFS_STATE: Rfs = {
  masterAmount: 'buyAmount',
  buyAmount: 0,
  buyCurrency: 'USD',
  sellAmount: 0,
  sellCurrency: 'EUR',
  status: 'idle',
  date: today,
  spotDate: today,
  postTradeOperation: undefined,
};

export const rfsReducer: Reducer<Rfs, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_RFS_STATE,
  action,
): Rfs => {
  switch (action.type) {
    case 'USER_LOADED':
      return {
        ...state,
        ...getDefaultCurrencies(action.user.companies[0].rfsCurrencies, [state.buyCurrency, state.sellCurrency]),
      };

    case 'CHANGE_COMPANY':
      return {
        ...state,
        ...getDefaultCurrencies(action.company.rfsCurrencies, [state.buyCurrency, state.sellCurrency]),
      };

    case 'FORM_CHANGE':
      return state.status === 'idle'
        ? {
            ...state,
            ...action.formChange,
          }
        : {
            ...state,
            ...action.formChange,
            status: 'cancelling',
          };

    case 'LOCATION_CHANGE':
      return { ...state, date: isRolloverRoute(action.pathname) ? state.date : state.spotDate };

    case 'CASH_QUOTE_RECEIVED':
      if (state.status === 'requesting' || state.status === 'quoting') {
        const quote =
          state.masterAmount === 'buyAmount'
            ? {
                sellAmount: action.contraAmount,
              }
            : {
                buyAmount: action.contraAmount,
              };

        if (state.product === 'FxFwd') {
          return {
            ...state,
            product: state.product,
            status: 'quoting',
            quoteId: action.quoteId,
            ...quote,
            spotRate: getBidAskValue(action.spotRate, action.sgWay),
            forwardPoints: getBidAskValue(action.forwardPoints, action.sgWay),
            forwardRate: getBidAskValue(action.forwardRate, action.sgWay),
            sgWay: action.sgWay,
            serverTimestamp: action.timestamp,
            clientTimestamp: Date.now(),
          };
        }

        if (state.product === 'FxSpot') {
          return {
            ...state,
            product: state.product,
            status: 'quoting',
            quoteId: action.quoteId,
            ...quote,
            spotRate: getBidAskValue(action.spotRate, action.sgWay),
            sgWay: action.sgWay,
            serverTimestamp: action.timestamp,
            clientTimestamp: Date.now(),
          };
        }

        return state;
      }

      logger.logWarning('Quote received while status != requesting | quoting', state);
      return state;

    case 'SWAP_QUOTE_RECEIVED':
      if (state.status === 'requesting' || state.status === 'quoting') {
        if (state.postTradeOperation === 'Predeliver') {
          const quote =
            state.masterAmount === 'buyAmount'
              ? {
                  sellAmount: Number.parseFloat(action.contraAmount),
                }
              : {
                  buyAmount: Number.parseFloat(action.contraAmount),
                };

          return {
            ...state,
            product: 'FxPredeliver',
            status: 'quoting',
            quoteId: action.quoteId,
            ...quote,
            spotRate: Number.parseFloat(getBidAskValue(action.spotRate, action.sgWay)),
            predeliverPoints: Number.parseFloat(getBidAskValue(action.nearPoints, action.sgWay)),
            predeliverRate: Number.parseFloat(getBidAskValue(action.nearRate, action.sgWay)),
            rolloverPoints: Number.parseFloat(getBidAskValue(action.farPoints, action.sgWay)),
            rolloverRate: Number.parseFloat(getBidAskValue(action.farRate, action.sgWay)),
            sgWay: action.sgWay,
            historicalDealOffRate: action.historicalDealOffRate,
            serverTimestamp: action.timestamp,
            clientTimestamp: Date.now(),
          };
        }

        // spec: https://itbox-jira.fr.world.socgen/jira/browse/SGEFX-3584
        const spotRate = Number.parseFloat(getBidAskValue(action.spotRate, action.sgWay));
        const nearRate = spotRate + Number.parseFloat(getBidAskValue(action.nearPoints, action.sgWay)); // the values in the action for nearRates are equal to 0

        const NearPoints = Number.parseFloat(getBidAskValue(action.nearPoints, action.sgWay));
        const farPoints = Number.parseFloat(getBidAskValue(action.farPoints, action.sgWay));
        const rolloverPoints = farPoints - NearPoints;
        const rolloverRate = spotRate + rolloverPoints; // the values in the action for nearRates are equal to 0

        return {
          ...state,
          product: 'FxRollover',
          status: 'quoting',
          quoteId: action.quoteId,
          [oppositeMasterAmount(state.masterAmount)]: state[state.masterAmount] / rolloverRate,
          spotRate,
          nearRate,
          rolloverPoints,
          rolloverRate,
          sgWay: action.sgWay,
          serverTimestamp: action.timestamp,
          clientTimestamp: Date.now(),
        };
      }

      logger.logWarning('Quote received while status != requesting | quoting', state);

      return state;

    case 'REQUEST_FOR_STREAM':
      return {
        ...state,
        status: 'starting-request',
        id: action.rfsId,
        [state.masterAmount === 'buyAmount' ? 'sellAmount' : 'buyAmount']: 0,
      };

    case 'STREAM_REQUESTED':
      return state.status === 'starting-request'
        ? {
            ...state,
            status: 'requesting',
            product: action.product,
          }
        : state;

    case 'ADD_ERROR':
      return { ...state, status: 'idle', spotRate: undefined, forwardPoints: undefined };

    case 'EXECUTE':
      logger.logInformation('Actual status when recieving EXECUTE action: {status}', { status: state.status });

      if (state.status === 'quoting' && state.spotRate !== undefined) {
        return { ...state, status: 'executing' };
      }
      return state;

    case 'EXECUTED':
      if (state.status === 'executing' || (state.status === 'execution-timeout' && state.spotRate !== undefined)) {
        return { ...state, status: 'executed' };
      }

      return state;

    case 'BACK_OFFICE_VALIDATED':
      if (state.status === 'executed') {
        return { ...state, status: 'backOfficeValidated', tradeId: action.tradeId };
      }
      return state;

    case 'EXECUTION_FAILED':
      if (state.status === 'executing' || state.status === 'execution-timeout') {
        return { ...state, status: 'execution-failed' };
      }
      return state;

    case 'EXECUTION_TIMEOUT':
      if (state.status === 'executing') {
        return { ...state, status: 'execution-timeout' };
      }
      return state;

    case 'TIMEOUTED_DEAL':
      if (state.status === 'executing') {
        return { ...state, status: 'timeouted-deal' };
      }
      return state;

    case 'QUOTING_TIMEOUT':
      if (state.status === 'quoting') {
        return { ...state, status: 'quoting-timeout' };
      }
      return state;

    case 'RESET':
      return {
        ...DEFAULT_RFS_STATE,
        buyCurrency: state.buyCurrency,
        sellCurrency: state.sellCurrency,
        date: state.date,
        masterAmount: state.masterAmount,
        spotDate: state.spotDate,
      };

    case 'SPOT_DATE_RECEIVED':
      return {
        ...state,
        date: today === state.date || state.spotDate === state.date ? action.date : state.date,
        spotDate: action.date,
      };

    case 'STREAM_STOPPED':
      if (state.status !== 'idle' && state.id !== action.rfsId) {
        return state;
      }
      return {
        ...DEFAULT_RFS_STATE,
        buyCurrency: state.buyCurrency,
        sellCurrency: state.sellCurrency,
        date: state.date,
        masterAmount: state.masterAmount,
        spotDate: state.spotDate,
        [state.masterAmount]: state[state.masterAmount],
        postTradeOperation: state.postTradeOperation,
        useAllAvailable: state.useAllAvailable,
      };

    case 'IS_ALL_AVAILABLE_USED_CHANGE':
      return {
        ...state,
        useAllAvailable: action.isAllAvailableUsed,
      };

    default:
      return state;
  }
};
