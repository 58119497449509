import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 } from 'uuid';
import { logger } from '@/logging/logger';
import { ProductName } from '@/models/product';
import { ExecWay, SgWay } from '@/models/way';
import { http } from '@/utils/http';

export const getSpotDate = (currencyPair: string) => {
  const request: TradeCaptureDto = {
    idVersion: 0,
    changedFields: {
      currencyPair,
      legs: { 0: { productName: 'FxSpot' } },
    },
  };

  return http.post(`myfx/tradeCapture/${v4()}/FxSpot`, request).pipe(
    map(res => res.response.result as TradeCaptureDto),
    map(resp => resp.changedFields.legs['0'].maturityDate),
  );
};

export const getTradeCapture = (
  productName: ProductName,
  buyAmount: number,
  sellAmount: number,
  currencyPair: string,
  maturityDate: string,
) => {
  const request: TradeCaptureDto = {
    idVersion: 0,
    changedFields: {
      currencyPair,
      legs: {
        0: {
          productName,

          amount1String: buyAmount !== 0 ? String(buyAmount) : undefined,
          amount2String: sellAmount !== 0 ? String(sellAmount) : undefined,
          maturityDateString: productName !== 'FxSpot' ? maturityDate : undefined,
        },
      },
    },
  };

  return http.post(`myfx/tradeCapture/${v4()}/${productName}`, request).pipe(
    map(res => {
      const tcResponse: TradeCaptureResponse = res.response;
      if (tcResponse.isSuccess) return tcResponse.result;
      logger.logError('Error with tradeCapture', tcResponse);
      throw tcResponse;
    }),
    catchError(err => {
      logger.logError('Error with tradeCapture', err);
      throw err;
    }),
  );
};

export const getClosedDates = (currencyPair: string) =>
  http.post(`myfx/dates/closed`, [currencyPair]).pipe(
    map(res => res.response),
    map(resp => resp[currencyPair] as string[]),
  );

export const requestForQuotes = (
  rfsId: string,
  currency1: string,
  currency2: string,
  negociatedAmount: number,
  negociatedCurrency: 1 | 2,
  date: string,
  product: ProductName,
  counterpartyId: string,
  connectionId: string,
  tradeId?: string,
  rolloverNearDate?: string,
) => {
  const payload: RfsRequestDto = {
    fxCashProduct:
      product !== 'FxSwap'
        ? {
          currencyPair: `${currency1}/${currency2}`,
          negociatedAmount,
          negociatedCurrency,
          expiryDate: date,
          cashType: product,
          tradeReference: tradeId,
        }
        : undefined,
    fxSwapProduct:
      product === 'FxSwap'
        ? {
          swapType: 'FxSwap',
          farLeg: {
            negociatedAmount,
            valueDate: date,
          },
          nearLeg: {
            negociatedAmount,
            valueDate: rolloverNearDate ?? date,
          },
          currencyPair: `${currency1}/${currency2}`,
          negociatedCurrency,
          extendedSwapPrecision: true
        }
        : undefined,
    forceTradable: true,
    replyToStream: connectionId,
    rfsId,
    requesterCounterpartyId: parseInt(counterpartyId, 10),
  };

  return http.post('myfx/rfs/request', payload);
};

export const executeRfs = (
  rfsId: string,
  quoteId: string,
  priceWithMargin: number,
  forwardPoints: number,
  way: SgWay,
  connectionId: string,
  clientTimestamp: number,
  serverTimestamp: number,
) => {
  if (rfsId === undefined) {
    throw new Error('Trying to execute some RFS with undefined rfsId');
  }
  const timeNow = Date.now() + serverTimestamp - clientTimestamp;

  const execution: RfsExecuteDto = {
    replyToStream: connectionId,
    way: way === 'Buy' ? 'Bid' : 'Ask',
    rfsId,
    quoteId,
    forwardPoints,
    priceWithMargin,
    calculatedTimestamp: timeNow,
  };

  logger.logInformation(
    `Quote execution of Rfs Id ${rfsId}. Last price at: ${serverTimestamp}, execution click front at: ${timeNow}, execution click with delta at: ${execution.calculatedTimestamp}.`,
  );
  logger.logInformation('Execute', execution);

  return http.post('myfx/rfs/execute', execution);
};

export const cancelStreaming = (rfsId: string) => {
  // fire and forget
  http
    .post('myfx/rfs/cancel', {
      rfsId,
    })
    .pipe(
      catchError(err => {
        logger.logError('Cancel rfs failed', err);
        return of(true);
      }),
    )
    .subscribe();

  return of(true);
};

export interface TradeCaptureResponse {
  error: unknown;
  isSuccess: boolean;
  result: TradeCaptureDto;
}

export interface TradeCaptureDto {
  idVersion: number;
  changedFields: {
    currencyPair?: string;
    isReadyToPrice?: boolean;
    isPriceObsolete?: boolean;
    legs: {
      0: Partial<TradeCaptureLeg>;
    };
  };
  errors?: unknown;
  warnings?: unknown;
}

interface TradeCaptureLeg {
  productName: ProductName;
  maturityDate: string;
  currencyPair: string;
  // isPriceObsolete: boolean;
  maturityDateString: string;
  // maturityDateTenor: string;
  // currency1: string;
  // currency2: string;
  // isNonDeliverable: false;
  // fixingCurrency: string;
  // possibleFixingCurrencies: { default: string; possible: string[] };
  // fixingDate: string;
  // amount1: number | null;
  amount1String: string | null;
  // amount2: number | null;
  amount2String: string | null;
  // isReadyToPrice: boolean;
}

interface RfsRequestDto {
  fxCashProduct?: Partial<FxCashProduct>;
  fxSwapProduct?: Partial<FxSwapProduct>;
  replyToStream: string;
  rfsId: string;
  requesterCounterpartyId: number;
  forceTradable: true;
}

interface SwapLeg {
  price?: number;
  valueDate: string;
  negociatedAmount: number;
}

interface FxSwapProduct {
  swapType: 'FxSwap';
  currencyPair: string;
  negociatedCurrency: 1 | 2;
  farLeg: SwapLeg;
  nearLeg: SwapLeg;
  extendedSwapPrecision: true;
}

interface FxCashProduct {
  currencyPair: string;
  negociatedAmount: number;
  negociatedCurrency: 1 | 2;
  expiryDate: string;
  cashType: ProductName;
  tradeReference?: string;
}

interface RfsExecuteDto {
  replyToStream: string;
  rfsId: string;
  quoteId: string;
  way: ExecWay;
  priceWithMargin: number;
  forwardPoints: number;
  calculatedTimestamp: number;
}
